import React, { FunctionComponent, useState, useEffect } from 'react';
import styled from 'styled-components';
import SVG from 'react-inlinesvg';
import { Tooltip as ReactTooltip } from 'react-tooltip';

import dayjs from 'dayjs';

import { Oval } from 'react-loader-spinner';
import Translate from 'lib/Translate';

import Selectors from 'redux/Selectors';
import Actions from 'redux/Actions';
import { RootState, AppDispatch } from 'redux/store';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { BreadCrumb, Button, Colors, Fonts, TabView, DropdownInput } from '@dm/bigfish';
import { IOwnerUser, PaymentGatewayResponse, PaymentTypeEnum, PMTransactionTypeEnum, PropertyManagementTransactionResponse, PropertyManagementTransactionStatus, SortByEnum, ViewAllPMTransactionResponse } from '@dm/types';

import NavActions from 'lib/NavActions';

import MainContainer from 'components/MainContainer';

import InlineSelectionInput from 'components/InlineSelectionInputViewDocument';

import ButtonInfoPackages from 'components/ButtonInfoPackages';
import { toast } from 'react-toastify';
import FileListModal from 'components/FileListModal';
import UploadFileModal from 'components/UploadFileModal';
import media from 'lib/Breakpoints';
import SupportInfo from '../../../components/SupportInfo';
import Icons from '../../../assets/icons';
import Modal from './Modal';
import PaymentUpload from './PropertyManagementUpload';
import CreateTransactionModal from './CreateTransactionModal';

interface PropertyManagementViewDocumentsProps {
    loadingPropertyManagementDocumentData: boolean;
    errorPropertyManagementDocumentData: string;
    propertyManagementDocumentData: PropertyManagementTransactionResponse[] | null;
    loadingPropertyManagementTransactionStatusUpdate: boolean;
    errorPropertyManagementTransactionStatusUpdate: string;
    propertyManagementTransactionStatusUpdateSuccess: string;

    loadingPropertyManagementTransactionStatusUpdateGateway: boolean,
    errorPropertyManagementTransactionStatusUpdateGateway: string;
    propertyManagementTransactionStatusUpdateSuccessGateway: null | PaymentGatewayResponse;

    packagesPropertyManagementStatusUpdateModal: boolean;

    isModalOpen: boolean;

    loadingUserData: boolean;
    errorUserData: string;
    UserDataSuccess: IOwnerUser | undefined;

    packagesDataSort(propertyId: string, sort: number, transactionType: number): void;
    propertyManagementTransactionStatusUpdate(propertyId: string,
        transactionId: string,
        paymentType: number,
        status: number,
        bankName: string,
        bankNumber: string,
        accountName: string,): void;
    propertyManagementTransactionStatusUpdateGateway(propertyId: string,
        transactionId: string,
        paymentType: number,
        status: number,
        bankName: string,
        bankNumber: string,
        accountName: string,): void;
    setPackagesPropertyManagementStatusUpdateModal(state: boolean): void;
    getUserInfo: () => void;
    setIsUploadFileModalOpen: (value: boolean) => void;
    setIsFileViewModalOpen: (value: boolean) => void;
    fileList: ViewAllPMTransactionResponse['fileUrl'];
    setFileList: (value: ViewAllPMTransactionResponse['fileUrl']) => void;
    isPMTransactionModalOpen: boolean;
    setPMTransactionModalOpen: (state: boolean) => void;
    getAllPropertyDetails: (propertyId: string) => void;
}

interface NavParams {
    propertyIndividualId: string;
}

const PropertyManagementViewDocuments: FunctionComponent<PropertyManagementViewDocumentsProps> = (props: PropertyManagementViewDocumentsProps) => {
    const {
        loadingPropertyManagementDocumentData,
        errorPropertyManagementDocumentData,
        propertyManagementDocumentData,
        loadingPropertyManagementTransactionStatusUpdate,
        errorPropertyManagementTransactionStatusUpdate,
        propertyManagementTransactionStatusUpdateSuccess,
        packagesPropertyManagementStatusUpdateModal,

        isModalOpen,

        loadingPropertyManagementTransactionStatusUpdateGateway,
        errorPropertyManagementTransactionStatusUpdateGateway,
        propertyManagementTransactionStatusUpdateSuccessGateway,

        loadingUserData,
        errorUserData,
        UserDataSuccess,

        packagesDataSort,
        propertyManagementTransactionStatusUpdate,
        propertyManagementTransactionStatusUpdateGateway,
        setPackagesPropertyManagementStatusUpdateModal,
        getUserInfo,
        setIsUploadFileModalOpen,
        setIsFileViewModalOpen,
        fileList,
        setFileList,
        isPMTransactionModalOpen,
        setPMTransactionModalOpen,
        getAllPropertyDetails,
    } = props;

    const [dateOrder, setDateOrder] = useState<SortByEnum>(SortByEnum.updatedAtDesc);
    const [homeScreenTabView, setHomeScreenTabView] = useState('');

    const [showModal, setShowModal] = useState(false);
    const [showModalSetTransactionType, setShowModalSetTransactionType] = useState(0);
    const [transactionIdNumber, setTransactionIdNumber] = useState('');
    const [modalDocumentUrl, setModalDocumentUrl] = useState('');
    const [propertyManagementPaymentType, setpropertyManagementPaymentType] = useState<number | null>(null);

    const [bankName, setBankName] = useState('');
    const [bankNumber, setBankNumber] = useState('');
    const [accountName, setAccountName] = useState('');
    const [paymentMethodChoosed, setPaymentMethodChoosed] = useState('');

    const [typesOpt, setTypesOpt] = useState<string | PMTransactionTypeEnum>('9999');

    const useLocationstate = useLocation();
    const { propertyIndividualId } = useLocationstate.state as NavParams;

    const onClickSetAction = (ActionType: number, transactionId: string) => {
        setTransactionIdNumber(transactionId);

        if (ActionType === PropertyManagementTransactionStatus.Accepted) {
            setPackagesPropertyManagementStatusUpdateModal(true);
            setShowModalSetTransactionType(PropertyManagementTransactionStatus.Accepted);
        }
        if (ActionType === PropertyManagementTransactionStatus.Rejected) {
            setPackagesPropertyManagementStatusUpdateModal(true);
            setShowModalSetTransactionType(PropertyManagementTransactionStatus.Rejected);
        }
    };

    const [tabViewData] = useState([
        {
            text: Translate.t('GeneralUI.all'),
            onClickTabView: () => {
                setHomeScreenTabView(Translate.t('GeneralUI.all'));
            },
        },
    ]);

    const [breadCrumb] = useState(
        [
            {
                label: Translate.t('GeneralUI.BreadcrumblabelDashboard'),
                onClick: () => { NavActions.navToHome(); },
            },
            {
                label: Translate.t('GeneralUI.BreadcrumblabelTransactions'),
                onClick: () => { return false; },
            },
        ],
    );

    interface IOption {
        id: string | number,
        description: string | number,
    }

    const transactionTypesDropdownData: IOption[] = [
        {
            id: PMTransactionTypeEnum.topup,
            description: 'Topup',
        },
        {
            id: PMTransactionTypeEnum.transaction,
            description: 'Transaction',
        },
        {
            id: PMTransactionTypeEnum.withdrawal,
            description: 'Withdrawal',
        },
        {
            id: PMTransactionTypeEnum.rental,
            description: 'Rental',
        },

    ];

    const dateData: IOption[] = [{
        id: 0,
        description: Translate.t('Packages.ViewDocuments.DateDecending'),
    },
    {
        id: 1,
        description: Translate.t('Packages.ViewDocuments.DateAscending'),
    }];

    const alphabeticalOrder: IOption[] = [
        {
            id: 2,
            description: Translate.t('Packages.ViewDocuments.NameAscendingOrder'),
        }, {
            id: 3,
            description: Translate.t('Packages.ViewDocuments.NameDecendingOrder'),
        },
    ];

    const listHeaders: string[] = ['Date', 'Description', 'Amount', 'Status', 'Type', 'Actions'];

    const [selectedPropId, setSelectedPropId] = useState('');
    const [selectedTransId, setSelectedTransId] = useState('');

    useEffect(() => {
        packagesDataSort(propertyIndividualId, dateOrder, Number(typesOpt));
        getUserInfo();
        getAllPropertyDetails(propertyIndividualId);
    }, []);

    useEffect(() => {
        setpropertyManagementPaymentType(null);
    }, [propertyManagementTransactionStatusUpdateSuccess, propertyManagementTransactionStatusUpdateSuccessGateway]);

    useEffect(() => {
        if (UserDataSuccess) {
            setBankName(UserDataSuccess.financialInfo?.bankName || '');
            setBankNumber(UserDataSuccess.financialInfo?.bankAccNo || '');
            setAccountName(`${UserDataSuccess.firstName} ${UserDataSuccess.lastName} `);
        }
    }, [UserDataSuccess]);

    useEffect(() => {
        packagesDataSort(propertyIndividualId, dateOrder, Number(typesOpt));
        setHomeScreenTabView(Translate.t('GeneralUI.all'));
    }, [dateOrder, typesOpt]);

    const onBackPressedBreadCrumb = () => {
        NavActions.navBack();
    };

    const confirmPayment = (actionType: string) => {
        if (propertyManagementPaymentType === null && actionType === 'approve') {
            toast.error(Translate.t('Packages.PropertyManagement.NoPayment'));
        } else {
            if (actionType === 'approve') {
                if (propertyManagementPaymentType === PaymentTypeEnum.PaymentGateway) {
                    propertyManagementTransactionStatusUpdateGateway(
                        propertyIndividualId,
                        transactionIdNumber,
                        propertyManagementPaymentType,
                        PropertyManagementTransactionStatus.Accepted,
                        bankName,
                        bankNumber,
                        accountName,
                    );
                }
            }
            if (propertyManagementPaymentType === PaymentTypeEnum.Wallet) {
                propertyManagementTransactionStatusUpdate(
                    propertyIndividualId,
                    transactionIdNumber,
                    propertyManagementPaymentType,
                    PropertyManagementTransactionStatus.Accepted,
                    bankName,
                    bankNumber,
                    accountName,
                );
            }

            if (propertyManagementPaymentType === PaymentTypeEnum.ManualBankTransfer) {
                propertyManagementTransactionStatusUpdateGateway(
                    propertyIndividualId,
                    transactionIdNumber,
                    propertyManagementPaymentType,
                    PropertyManagementTransactionStatus.Accepted,
                    bankName,
                    bankNumber,
                    accountName,
                );
            }

            if (actionType === 'reject') {
                propertyManagementTransactionStatusUpdateGateway(
                    propertyIndividualId,
                    transactionIdNumber,
                    PaymentTypeEnum.Wallet,
                    PropertyManagementTransactionStatus.Rejected,
                    bankName,
                    bankNumber,
                    accountName,
                );
            }
        }
    };

    const checkPaymentMethodChoosed = (name: string) => {
        setPaymentMethodChoosed(name);
    };

    const checkTransactionAmount = (amount: number) => {
        if (amount < 0) {
            return <p style={{ color: Colors.danger }}>{`RM ${Math.abs(amount)}`}</p>;
        }
        return <p style={{ color: Colors.success }}>{`RM ${amount}`}</p>;
    };

    const checkPropertyPackagesStatus = (packages: number) => {
        const packagesnumber = packages;
        switch (packagesnumber) {
            case PropertyManagementTransactionStatus.Pending:
                return (Translate.t('Packages.PropertyManagement.StatusPending'));
            case PropertyManagementTransactionStatus.Accepted:
                return (Translate.t('Packages.PropertyManagement.StatusAccepted'));
            case PropertyManagementTransactionStatus.Rejected:
                return (Translate.t('Packages.PropertyManagement.StatusRejected'));
            case PropertyManagementTransactionStatus.Completed:
                return (Translate.t('Packages.PropertyManagement.StatusCompleted'));
            default:
                return (Translate.t('Packages.PropertyManagement.StatusNoStatus'));
        }
    };

    const checkTransactionTypes = (type: number) => {
        switch (type) {
            case PMTransactionTypeEnum.topup:
                return 'Topup';
            case PMTransactionTypeEnum.transaction:
                return 'Transaction';
            case PMTransactionTypeEnum.withdrawal:
                return 'Withdrawal';
            case PMTransactionTypeEnum.rental:
                return 'Rental';
            default:
                return 'no status';
        }
    };

    const viewFileHandler = (data: ViewAllPMTransactionResponse['fileUrl']) => {
        setFileList(data);
        setIsFileViewModalOpen(true);
    };

    const renderActionButtons = (transactionId: string, status: number, data: ViewAllPMTransactionResponse['fileUrl']) => {
        return (
            <td id='actions'>
                <StyledIconButton
                    disabled={data.length === 0}
                    onClick={() => viewFileHandler(data)}
                    style={{ alignSelf: 'center', marginRight: '10px' }}
                >
                    <ButtonIcons src={Icons.Search} width='30px' height='30px' />
                </StyledIconButton>
                <ButtonMainContainer isPending={status === PropertyManagementTransactionStatus.Pending}>
                    <ApproveContainer>
                        <StyledMainButton
                            onClick={() => onClickSetAction(PropertyManagementTransactionStatus.Accepted, transactionId)}
                        >

                            <UploadDocumentText>
                                {loadingPropertyManagementTransactionStatusUpdate ? (
                                    <Oval
                                        height={25}
                                        width={25}
                                        color={Colors.secondary}
                                        secondaryColor={Colors.primary}
                                    />
                                ) : <div>{Translate.t('Packages.PropertyManagement.StatusApprove')}</div>}
                            </UploadDocumentText>
                        </StyledMainButton>

                    </ApproveContainer>

                    <InputItemContainer>
                        <StyledMainButton
                            onClick={() => onClickSetAction(PropertyManagementTransactionStatus.Rejected, transactionId)}
                        >

                            <UploadDocumentText>
                                {loadingPropertyManagementTransactionStatusUpdate ? (
                                    <Oval
                                        height={25}
                                        width={25}
                                        color={Colors.secondary}
                                        secondaryColor={Colors.primary}
                                    />
                                ) : <div>{Translate.t('Packages.PropertyManagement.StatusDelete')}</div>}
                            </UploadDocumentText>
                        </StyledMainButton>

                    </InputItemContainer>
                </ButtonMainContainer>
            </td>
        );
    };

    const renderList = () => {
        if (loadingPropertyManagementDocumentData) {
            return (
                <tr>
                    <td id='loading' colSpan={8}>
                        <div>
                            <Oval
                                height={250}
                                width={250}
                                color={Colors.secondary}
                                secondaryColor={Colors.primary}
                            />
                        </div>
                    </td>
                </tr>
            );
        }

        if (errorPropertyManagementDocumentData.length) {
            return (
                <tr>
                    <td colSpan={8}>
                        {Translate.t('Packages.ViewDocuments.NoData')}
                    </td>
                </tr>
            );
        }

        if (propertyManagementDocumentData?.length) {
            return (
                <>
                    {propertyManagementDocumentData.map((item) => {
                        const { transactionsId, amount, description, status, transactionType, fileUrl, createdAt } = item;

                        return (
                            <tr>
                                <td>{createdAt ? dayjs(createdAt).format('DD/MM/YYYY') : ''}</td>
                                <td>{description}</td>
                                <td>
                                    {checkTransactionAmount(amount)}
                                </td>
                                <td>
                                    {checkPropertyPackagesStatus(status)}
                                </td>
                                <td>
                                    {checkTransactionTypes(transactionType)}
                                </td>
                                {renderActionButtons(transactionsId, status, fileUrl)}
                            </tr>
                        );
                    })}
                </>
            );
        }

        return (
            <tr>
                <td colSpan={8}>
                    {Translate.t('Packages.ViewDocuments.NoData')}
                </td>
            </tr>
        );
    };

    return (
        <>
            <BreadCrumb backLabel={Translate.t('Packages.ViewDocuments.Back')} onBackPressed={onBackPressedBreadCrumb} data={breadCrumb} />
            <MainContainer>
                <InfoContainerMain>
                    <LeftContainer>
                        <InfoContainer>
                            <TabViewContainer>
                                <TabView data={tabViewData} selectedTabView={homeScreenTabView} />
                            </TabViewContainer>
                            <TitleMessageContainer>{Translate.t('Packages.ViewDocuments.TitleMessage')}</TitleMessageContainer>
                            <FiltersContainer>

                                <FilterTitleBold>{Translate.t('Packages.PackagesCard.CardSubTitlePropertyManagementTransactions')}</FilterTitleBold>

                                <FiltersInputContainer>
                                    <DropdownInput
                                        style={{ marginRight: '15px' }}
                                        label={Translate.t('Packages.ViewDocuments.TransactionType')}
                                        value={typesOpt}
                                        options={transactionTypesDropdownData}
                                        onChangeItem={setTypesOpt}
                                    />
                                    <InlineSelectionInput
                                        data={dateData}
                                        onChangeSelection={e => setDateOrder(Number(e.target.value))}
                                    />
                                    <InlineSelectionInput
                                        data={alphabeticalOrder}
                                        defaultValue={SortByEnum.updatedAtDesc.toString()}
                                        onChangeSelection={e => setDateOrder(Number(e.target.value))}
                                    />
                                    {/* <Button label='Create Transaction' onClick={() => setPMTransactionModalOpen(true)} size='md' style={{}} /> */}
                                </FiltersInputContainer>
                                <CreateTransactionModal propertyId={propertyIndividualId} />
                            </FiltersContainer>
                            <ReportTableContainer>
                                <ReportTable>
                                    <tr id='header'>
                                        {listHeaders.map((item) => {
                                            return (
                                                <th
                                                    key={`header-${item}`}
                                                >
                                                    {item}
                                                </th>
                                            );
                                        })}
                                    </tr>
                                    {renderList()}
                                </ReportTable>
                            </ReportTableContainer>
                        </InfoContainer>
                    </LeftContainer>

                    <RightContainer>
                        <SupportInfo />
                        <NavigationContainer>
                            <QuickNavigation>{Translate.t('Packages.PackagesCard.QuickNavigation')}</QuickNavigation>
                            <QuickNavigationDescription>{Translate.t('Packages.PackagesCard.QuickLink')}</QuickNavigationDescription>
                            <ButtonInfoPackagesStyled desc={Translate.t('Packages.PackagesCard.CardTitleVacantPossession')} rightIconSrc={Icons.RightArrow} onclick={() => NavActions.navToViewVacantPossessionPackages(propertyIndividualId)} />
                            <ButtonInfoPackagesStyled desc={Translate.t('Packages.PackagesCard.CardTitlePropertyManagement')} rightIconSrc={Icons.RightArrow} onclick={() => NavActions.navToViewPropertyManagementPackages(propertyIndividualId)} />
                            <ButtonInfoPackagesStyled desc={Translate.t('Packages.PackagesCard.CardTitleDefectInspection')} rightIconSrc={Icons.RightArrow} onclick={() => NavActions.navToViewDefectInspectionPackages(propertyIndividualId)} />
                        </NavigationContainer>
                    </RightContainer>
                    <UploadFileModal
                        propertyId={selectedPropId}
                        transactionId={selectedTransId}
                    />
                    <FileListModal />
                </InfoContainerMain>
                <div>
                    {isModalOpen && (
                        <PaymentUpload />
                    )}
                </div>

            </MainContainer>

            <Modal
                show={showModal}
                closeCursor
                onClickClose={() => {
                    setShowModal(false);
                }}
            >
                <ModalContent>
                    <CarouselPhoto src={modalDocumentUrl} title='pdf' />
                </ModalContent>
            </Modal>
            <Modal
                show={packagesPropertyManagementStatusUpdateModal}
                closeCursor
                width='50%'
                height='40%'
                onClickClose={() => {
                    setPackagesPropertyManagementStatusUpdateModal(false);
                }}
            >
                <ModalContent>
                    {showModalSetTransactionType === PropertyManagementTransactionStatus.Accepted ? (
                        <ModalContentMainContainer>
                            <h4>{Translate.t('Packages.PropertyManagement.SelectPaymentMethod')}</h4>

                            <TransactionStatusUpdteButtonMainContainer>
                                <ButtonContainer>
                                    <StyledMainButton
                                        onClick={() => [setpropertyManagementPaymentType(PaymentTypeEnum.ManualBankTransfer), checkPaymentMethodChoosed('Manual Bank Transfer')]}
                                    >

                                        <UploadDocumentText>
                                            <h5>{Translate.t('Packages.PropertyManagement.ManualBankTransfer')}</h5>
                                        </UploadDocumentText>
                                    </StyledMainButton>

                                </ButtonContainer>

                                <ButtonContainer>
                                    <StyledMainButton
                                        onClick={() => [setpropertyManagementPaymentType(PaymentTypeEnum.Wallet), checkPaymentMethodChoosed('wallet')]}
                                    >

                                        <UploadDocumentText>
                                            <h5>{Translate.t('Packages.PropertyManagement.Wallet')}</h5>
                                        </UploadDocumentText>
                                    </StyledMainButton>

                                </ButtonContainer>

                                <ButtonContainer>
                                    <StyledMainButton
                                        onClick={() => [setpropertyManagementPaymentType(PaymentTypeEnum.PaymentGateway), checkPaymentMethodChoosed('Stripe Payment')]}
                                    >

                                        <UploadDocumentText>
                                            <h5>{Translate.t('Packages.PropertyManagement.StripePayment')}</h5>
                                        </UploadDocumentText>
                                    </StyledMainButton>

                                </ButtonContainer>
                            </TransactionStatusUpdteButtonMainContainer>
                            {paymentMethodChoosed !== '' && (
                                <div>
                                    <PaymentMethodText>
                                        {Translate.t('Packages.PropertyManagement.PaymentMethod')}
                                        {' '}
                                        {paymentMethodChoosed}
                                    </PaymentMethodText>
                                </div>
                            )}

                            {((loadingPropertyManagementTransactionStatusUpdate) || (loadingPropertyManagementTransactionStatusUpdateGateway)) && (
                                <Oval
                                    height={50}
                                    width={50}
                                    color={Colors.secondary}
                                    secondaryColor={Colors.primary}
                                />
                            )}

                            <ModalContentContainer>
                                <Button
                                    label={Translate.t('Packages.PropertyManagement.Confirm')}
                                    disabled={loadingPropertyManagementTransactionStatusUpdate}
                                    onClick={() => [confirmPayment('approve'), checkPaymentMethodChoosed('')]}
                                />

                            </ModalContentContainer>
                        </ModalContentMainContainer>
                    )
                        : (
                            <ModalContentMainContainer>
                                <h4>{Translate.t('Packages.PropertyManagement.ApproveRejection')}</h4>
                                {loadingPropertyManagementTransactionStatusUpdate && (
                                    <Oval
                                        height={100}
                                        width={100}
                                        color={Colors.primary}
                                        secondaryColor={Colors.primary}
                                    />
                                )}

                                <ModalContentContainer>
                                    <Button
                                        label={Translate.t('Packages.PropertyManagement.Confirm')}
                                        disabled={loadingPropertyManagementTransactionStatusUpdate}
                                        onClick={() => [confirmPayment('reject'), checkPaymentMethodChoosed('')]}

                                    />
                                </ModalContentContainer>
                            </ModalContentMainContainer>
                        )}
                </ModalContent>

            </Modal>
        </>
    );
};

const ModalContentMainContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 100%;
`;

const ModalContentContainer = styled.div`
    display: flex;
    flex-direction: row-reverse;
    width: 100%;
`;

const PaymentMethodText = styled.p`
    display: flex;
`;

const CarouselPhoto = styled.iframe`
    width: 100%;
    height: 100%;
    object-fit: cover;
`;

const ModalContent = styled.div`
    width: 100%;
    height: 100%;
    padding: 40px 10px 10px 10px;
`;

const StyledIconButton = styled.button`
    display: inline-block;
    border: none;
    background: transparent;
    cursor: pointer;
    transition: all .2s;

    &:active {
        transform: translateY(-3px);
    }
`;

const TransactionStatusUpdteButtonMainContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-self: center;
`;

const ButtonMainContainer = styled.div<{ isPending?: boolean }>`
    display: ${props => (props.isPending ? 'flex' : 'none')};;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: fit-content;
`;

const StyledMainButton = styled.button`
    width: 100%;
    display: flex;
    align-items: center;
    background-color: transparent;
    border: none;
    padding: 8px;
    cursor: pointer;
    color: white;
`;

const InputItemContainer = styled.div`
    display: flex;
    align-items: center;
    min-height: 40px;
    max-width: 100%;
    width: 85px;
    border-radius: 8px;
    background-color: #F2B47E;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    color: white;
`;

const ButtonContainer = styled.div`
    display: flex;
    align-items: center;
    min-height: 40px;
    max-width: 100%;
    width: 85px;
    border-radius: 8px;
    margin-bottom: 8px;
    background-color: #F2B47E;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    color: #ffffff;
    margin-left: 5px;
    &:hover{
        opacity: 0.7;
        cursor: pointer;
    };
`;

const ApproveContainer = styled.div`
    display: flex;
    align-items: center;
    min-height: 40px;
    max-width: 100%;
    width: 85px;
    border-radius: 8px;
    margin-bottom: 8px;
    background-color: #38d140;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    color: #ffffff;
`;

const UploadDocumentText = styled.p`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
`;

const FilterTitleBold = styled.p`
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
`;

const TitleMessageContainer = styled.p`
    margin-bottom: 40px;
`;

const InfoContainerMain = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
`;

const LeftContainer = styled.div`
    display: flex;
    width: 100%;
    height: 100%;
`;

const RightContainer = styled.div`
    margin: 20px 10px;
    width: 100%;
    display: flex;
    gap: 20px;

    ${media.sm`
        flex-direction: column;
        width: unset;
    `}
`;

const QuickNavigation = styled.p`
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;
    margin-bottom: 20px;
`;

const QuickNavigationDescription = styled.p`
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
`;

const InfoContainer = styled.div`
    height: 100%;
    width: 100%;
    padding: 10px;
`;

const ButtonInfoPackagesStyled = styled(ButtonInfoPackages)`
    margin-top: 12px;
`;

const TabViewContainer = styled.div`
    margin-top: 10px;
    margin-bottom: 20px;
`;

const NavigationContainer = styled.div`
    display: flex;
    flex-direction: column;
    padding: 20px;
    margin-inline: 20px;
    width: 100%;
    display: flex;
    box-shadow: -4px 0px 4px rgba(0, 0, 0, 0.05), 4px 0px 4px rgba(0, 0, 0, 0.05), 0px 4px 4px rgba(0, 0, 0, 0.05), 0px -4px 4px rgba(0, 0, 0, 0.05);
    border-radius: 12px;

    ${media.sm`
        margin-inline: 0;
    `}
`;

const FiltersContainer = styled.div`
    width: 100%;
    margin-top: 20px;
    padding: 20px;
    background: ${Colors.white};
    box-sizing: border-box;
    border-radius: 12px;
    box-shadow: -4px 0px 4px rgba(0, 0, 0, 0.05), 4px 0px 4px rgba(0, 0, 0, 0.05), 0px 4px 4px rgba(0, 0, 0, 0.05), 0px -4px 4px rgba(0, 0, 0, 0.05);
`;

const FiltersInputContainer = styled.div`
    margin-top: 10px;
    display: flex;
    justify-content: left;
    align-items: center;
`;

const ButtonIcons = styled(SVG) <{ width: string, height?: string }>`
    display: flex;
    justify-content: center;
    align-items: center;
    width: ${props => props.width};
    height: ${props => props.height};
    padding-inline: 5px;

    ${media.sm`
        width: 30px;
        height: 30px;
    `}
`;

const ReportTableContainer = styled.div`
    width: 100%;
    padding-block: 20px;

    ${media.sm`
        overflow-x: auto;
    `}
`;

const ReportTable = styled.table`
    width: 100%;
    border-collapse: collapse;

    ${media.sm`
        width: max-content;
    `}

    td,
    th {
        padding: 20px;
        text-align: center;

        /* ${media.sm`
            width: 200px;
        `} */
    }

    #header {
        background-color: ${Colors.primaryLight};

        th:first-of-type {
            border-top-left-radius: 8px;
            border-bottom-left-radius: 8px;
        }

        th:last-of-type {
            border-top-right-radius: 8px;
            border-bottom-right-radius: 8px;
        }
    }

    #loading > div {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 20px;

        ${media.sm`
            width: 86vw;
        `}
    }

    #actions {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 20px;
    }

    tr:nth-child(odd) {
        background-color: ${Colors.greyLightest};

        td:first-of-type {
            border-top-left-radius: 15px;
            border-bottom-left-radius: 15px;
        }

        td:last-of-type {
            border-top-right-radius: 15px;
            border-bottom-right-radius: 15px;
        }
    }
`;

const mapStateToProps = (state: RootState) => ({
    loadingPropertyManagementDocumentData: Selectors.getPackagesIPropertyManagementDataDetailsSortAttempt(state),
    errorPropertyManagementDocumentData: Selectors.getPackagesIPropertyManagementDataDetailsSortFailure(state),
    propertyManagementDocumentData: Selectors.getIPropertyManagementDataDetailsSort(state),

    loadingPropertyManagementTransactionStatusUpdate: Selectors.packagesSetPropertyManagementTransactionUpdateSuccessAttempting(state),
    errorPropertyManagementTransactionStatusUpdate: Selectors.packagesSetPropertyManagementTransactionUpdateSuccessError(state),
    propertyManagementTransactionStatusUpdateSuccess: Selectors.packagesSetPropertyManagementTransactionUpdateSuccess(state),

    loadingPropertyManagementTransactionStatusUpdateGateway: Selectors.packagesSetPropertyManagementTransactionUpdateSuccessGatewayAttempting(state),
    errorPropertyManagementTransactionStatusUpdateGateway: Selectors.packagesSetPropertyManagementTransactionUpdateSuccessGatewayError(state),
    propertyManagementTransactionStatusUpdateSuccessGateway: Selectors.packagesSetPropertyManagementTransactionUpdateSuccessGateway(state),

    packagesPropertyManagementStatusUpdateModal: Selectors.packagesSetpackagesPropertyManagementStatusUpdateModal(state),

    loadingUserData: Selectors.getUserGetUserInfoAttempt(state),
    errorUserData: Selectors.getUserGetUserInfoFailure(state),
    UserDataSuccess: Selectors.getUserGetUserInfo(state),

    isModalOpen: Selectors.packagesSetTopUpPaymentModal(state),
    fileList: Selectors.getUiGetFileList(state),
    isPMTransactionModalOpen: Selectors.getUiIsPMTransactionModalOpen(state),
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
    packagesDataSort: (propertyId: string, sort: number, transactionType: number) =>
        dispatch(Actions.packagesGetIPropertyManagementDataSortAttempt({ propertyId, sort, transactionType })),

    propertyManagementTransactionStatusUpdate: (
        propertyId: string,
        transactionId: string,
        paymentType: number,
        status: number,
        bankName: string,
        bankNumber: string,
        accountName: string,
    ) =>
        dispatch(Actions.packagesSetPropertyManagementPackageTransactionAttempt({
            propertyId,
            transactionId,
            paymentType,
            status,
            bankName,
            bankNumber,
            accountName,
        })),

    propertyManagementTransactionStatusUpdateGateway: (
        propertyId: string,
        transactionId: string,
        paymentType: number,
        status: number,
        bankName: string,
        bankNumber: string,
        accountName: string,
    ) =>
        dispatch(Actions.packagesSetPropertyManagementPackageTransactionGatewayAttempt({
            propertyId,
            transactionId,
            paymentType,
            status,
            bankName,
            bankNumber,
            accountName,
        })),

    setPackagesPropertyManagementStatusUpdateModal: (state: boolean) =>
        dispatch(Actions.setPackagesPropertyManagementStatusUpdateModalOpen(state)),
    getUserInfo: () =>
        dispatch(Actions.userGetUserInfoAttempt()),
    setIsUploadFileModalOpen: (value: boolean) => dispatch(Actions.setUploadFileModalOpen(value)),
    setIsFileViewModalOpen: (state: boolean) => dispatch(Actions.setFileViewModalOpen(state)),
    setFileList: (data: ViewAllPMTransactionResponse['fileUrl']) => dispatch(Actions.setFileList(data)),
    setPMTransactionModalOpen: (state: boolean) => dispatch(Actions.setPMTransactionModalOpen(state)),
    getAllPropertyDetails: (propertyId: string) => dispatch(Actions.getAllPropertyDetailsAttempt({ propertyId })),
});

export default connect(mapStateToProps, mapDispatchToProps)(PropertyManagementViewDocuments);
