import { Button, Colors, Fonts, RoundInput } from '@dm/bigfish';
import Icons from 'assets/icons';
import React, { PureComponent, useEffect, useState } from 'react';
import styled, { FlattenSimpleInterpolation } from 'styled-components';

import SVG from 'react-inlinesvg';
import { AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, CartesianAxis } from 'recharts';
import { connect } from 'react-redux';
import { AppDispatch, RootState } from 'redux/store';
import Selectors from 'redux/Selectors';
import Actions from 'redux/Actions';
import { IPropertyPaginator, IWalletAnalytics } from 'entities/property';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import ReactDatePicker from 'react-datepicker';
import NavActions from 'lib/NavActions';
import { toast } from 'react-toastify';
import Translate from 'i18n-js';
import { Bars } from 'react-loader-spinner';
import media from 'lib/Breakpoints';
import Modal from './Modal';

dayjs.extend(utc);

interface transactionDetail {
    amount: number;
    remarks: string;
    createdAt?: string;
}

export interface WalletStatisticProps {
    displayedProperty: IPropertyPaginator['data'];
    walletTotal: number;
    transactions: transactionDetail[];
    onClick: () => void;
    allWalletAnalytics: IWalletAnalytics[];
    getWalletAnalyticsAttempt: boolean;
    getWalletAnalyticsFailure: string;
    getWalletAnalytics: (propertyId: string, dateFrom: string, dateTo: string) => void;
    loading?: boolean;
    hideViewButton?: boolean;
    css?: FlattenSimpleInterpolation;
    [x: string]: any;
}

const CustomXAxisTick = (props: any) => {
    const { x, y, payload } = props;
    const newDate = dayjs(payload.value).format('DD/MM');
    return (
        <text x={x} y={y + 10} fill='#666' textAnchor='middle' dominantBaseline='middle' fontSize={12}>
            {newDate}
        </text>

    );
};

const CustomYAxisTick = (props: any) => {
    const { x, y, payload } = props;
    const absoluteValue = Math.abs(payload.value).toLocaleString();
    if (payload.value < 0) {
        return (
            <text x={x} y={y} fill='#666' textAnchor='end' dominantBaseline='middle' fontSize={12}>
                {`- RM ${absoluteValue}`}
            </text>
        );
    }
    return (
        <text x={x} y={y} fill='#666' textAnchor='end' dominantBaseline='middle' fontSize={12}>
            {`RM ${absoluteValue}`}
        </text>
    );
};

const CustomTooltip = (props: any) => {
    const { active, payload } = props;
    if (active && payload && payload.length) {
        const { date, value } = payload[0].payload;
        const formattedDate = dayjs(date).format('DD-MM-YYYY');
        const formattedValue = `RM ${Number(value).toFixed(2)}`;

        return (
            <div style={{ backgroundColor: 'white', padding: '10px', borderRadius: '5px' }}>
                <p>{formattedDate}</p>
                <p>{formattedValue}</p>
            </div>
        );
    }

    return null;
};

const WalletStatistic = (props: WalletStatisticProps): JSX.Element => {
    const {
        displayedProperty,
        walletTotal,
        transactions,
        onClick,
        allWalletAnalytics,
        getWalletAnalyticsAttempt,
        getWalletAnalyticsFailure,
        getWalletAnalytics,
        loading,
        hideViewButton,
    } = props;

    const [startDate, setStartDate] = useState(dayjs().subtract(30, 'days').toDate());
    const [endDate, setEndDate] = useState(new Date());
    const [count, setCount] = useState(0);
    const [topUpModal, setTopUpModal] = useState(false);
    const [topupAmount, setTopupAmount] = useState('0');

    const [propertyId, setPropertyId] = useState('');

    const today = new Date();
    useEffect(() => {
        const min = 1000; // Minimum four-digit number
        const max = 9999; // Maximum four-digit number
        const randomNumber = Math.floor(Math.random() * ((max - 3421) - min + 1)) + min; // Generate a random four-digit number
        let currentCount = 0;
        const timer = setInterval(() => {
            currentCount += randomNumber;
            if (currentCount > max) {
                currentCount = min;
            }
            setCount(currentCount);
        }, 50);

        setTimeout(() => {
            clearInterval(timer);
        }, 3000);
    }, [getWalletAnalyticsAttempt]);

    useEffect(() => {
        setStartDate(dayjs().subtract(30, 'days').toDate());
        setEndDate(today);
    }, [displayedProperty]);

    useEffect(() => {
        if (displayedProperty.length > 0) {
            const selectedProperty = displayedProperty.find((item) => item.selected === true);
            if (selectedProperty) {
                const newStartDate = dayjs(startDate).startOf('day').utc().format();
                const newEndDate = dayjs(endDate).endOf('day').utc().format();
                getWalletAnalytics(
                    selectedProperty.propertyId,
                    newStartDate,
                    newEndDate,
                );
                setPropertyId(selectedProperty.propertyId);
            }
        }
    }, [displayedProperty, startDate, endDate]);

    const setAmountfunction = (e: React.ChangeEvent<HTMLInputElement>) => {
        e.preventDefault();
        setTopupAmount(e.target.value);
    };

    const confirmNavigateToPayment = () => {
        if (Number(topupAmount) === 0 || Number.isNaN(Number(topupAmount))) {
            toast.error(Translate.t('TopUp.EnterAmount'));
        } else {
            NavActions.navToTopupWallet(propertyId, Number(topupAmount));
        }
    };

    const renderWalletTitle = () => {
        if (displayedProperty.length > 0) {
            return (
                <WalletFunction loading={loading}>
                    {/* <WalletFunctionButtonLeft onClick={() => setTopUpModal(!topUpModal)}>Top up</WalletFunctionButtonLeft>
                    <WalletFunctionButtonRight>Withdraw</WalletFunctionButtonRight> */}
                    <Modal
                        show={topUpModal}
                        closeCursor
                        width='fit-content'
                        height='200px'
                        onClickClose={() => {
                            setTopUpModal(false);
                        }}
                    >
                        <ModalContentMainContainer>
                            <p style={{ textAlign: 'center' }}>{Translate.t('TopUp.EnterTopUpAmount')}</p>
                            <RoundInputStyled placeholder={Translate.t('TopUp.EnterAmount')} value={topupAmount} onChangeText={setAmountfunction} required />
                            <Button label={Translate.t('TopUp.Confirm')} size='sm' onClick={() => confirmNavigateToPayment()} />
                        </ModalContentMainContainer>
                    </Modal>
                </WalletFunction>
            );
        }
        return null;
    };

    const renderWalletChart = () => {
        if (allWalletAnalytics.length === 0) {
            return (
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                    There is no wallet transaction data. Please modify the date range.
                </div>
            );
        }

        if (getWalletAnalyticsAttempt || loading) {
            return (
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%' }}>
                    <Bars color={Colors.primary} height={200} width={200} />
                </div>
            );
        }
        const gradientOffset = () => {
            const dataMax = Math.max(...allWalletAnalytics.map((i) => i.value));
            const dataMin = Math.min(...allWalletAnalytics.map((i) => i.value));

            if (dataMax <= 0) {
                return 0;
            }
            if (dataMin >= 0) {
                return 1;
            }

            return dataMax / (dataMax - dataMin);
        };

        const off = gradientOffset();
        return (
            <ResponsiveContainer width='100%' height='100%'>
                <AreaChart
                    data={allWalletAnalytics}
                    margin={{
                        top: 100,
                        right: 0,
                        left: 0,
                        bottom: 0,
                    }}
                >
                    <defs>
                        <linearGradient id='splitColor' x1='0' y1='0' x2='0' y2='1'>
                            <stop offset={off} stopColor={Colors.success} stopOpacity={0.7} />
                            <stop offset={off} stopColor={Colors.danger} stopOpacity={0.7} />
                        </linearGradient>
                    </defs>
                    {/* <YAxis
                        tick={<CustomYAxisTick />}
                        tickLine={false}
                        axisLine={false}
                        // hide
                    />
                    <XAxis
                        interval={6}
                        tickLine={false}
                        axisLine={false}
                        dataKey='date'
                        tick={<CustomXAxisTick />}
                        hide
                    /> */}
                    <Tooltip
                        cursor={{ stroke: Colors.primary, strokeWidth: 2 }}
                        content={<CustomTooltip />}
                    />
                    <Area
                        type='linear'
                        dataKey='value'
                        stroke={Colors.primary}
                        strokeWidth={2}
                        fillOpacity={1}
                        fill='url(#splitColor)'
                        isAnimationActive={false}
                    />
                </AreaChart>
            </ResponsiveContainer>
        );
    };

    const renderMonthPicker = () => {
        if (loading) {
            return <div style={{ height: '26px' }} />;
        }
        return (
            <Filter>
                <StyledDatePicker
                    value={dayjs(startDate).format('DD/MM/YYYY')}
                    selected={startDate}
                    onChange={(e: Date) => setStartDate(e)}
                    selectsStart
                    startDate={startDate}
                    endDate={endDate}
                    dateFormat='MM/yyyy'
                    maxDate={today}
                />
                <p>to</p>
                <StyledDatePicker
                    value={dayjs(endDate).format('DD/MM/YYYY')}
                    selected={endDate}
                    onChange={(e: Date) => setEndDate(e)}
                    selectsEnd
                    startDate={startDate}
                    endDate={endDate}
                    dateFormat='MM/yyyy'
                    maxDate={today}
                />
            </Filter>
        );
    };

    const renderWalletTransactionList = () => {
        if (transactions.length === 0) {
            return (
                <p style={{ marginTop: '40px' }}>
                    There are no transactions yet. Please adjust the date range or create a transactions
                </p>
            );
        }
        if (loading) {
            return (
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                    <Bars color={Colors.primary} height={200} width={200} />
                </div>
            );
        }
        let slicedTransactions;
        if (transactions.length > 8) {
            slicedTransactions = transactions.slice(transactions.length - 7, transactions.length);
        } else {
            slicedTransactions = transactions.slice();
        }

        return (
            <TransactionListContainer>
                {

                    slicedTransactions.reverse().map((item, index) => {
                        const { amount, remarks, createdAt } = item;

                        const fixedAmount = amount.toFixed(2);
                        const absoluteAmount = Math.abs(Number(fixedAmount)).toLocaleString();

                        const keyValue = index + amount;

                        return (
                            <WalletTransactionItem key={keyValue}>
                                <p>{createdAt ? dayjs(createdAt).format('DD-MM-YYYY') : ''}</p>
                                <p>{remarks}</p>
                                {
                                    amount < 0 ? (
                                        <span>
                                            - RM
                                            {' '}
                                            {absoluteAmount}
                                        </span>
                                    ) : (
                                        <p>
                                            + RM
                                            {' '}
                                            {absoluteAmount}
                                        </p>
                                    )
                                }

                            </WalletTransactionItem>
                        );
                    })
                }
            </TransactionListContainer>
        );
    };

    return (
        <WalletStatisticContainer>
            <WalletTitle>
                <Title negativeTotal={walletTotal < 0}>
                    <p>Wallet</p>
                    <span>
                        RM
                        {' '}
                        {loading ? count : walletTotal.toFixed(2).toLocaleString()}
                    </span>
                </Title>
                {renderWalletTitle()}
            </WalletTitle>
            <WalletChart>
                <div style={{ display: 'flex', justifyContent: 'end' }}>
                    {renderMonthPicker()}
                </div>
                {renderWalletChart()}
            </WalletChart>
            <WalletTransaction>
                <WalletTransactionTitle>
                    Transactions
                </WalletTransactionTitle>
                {renderWalletTransactionList()}
            </WalletTransaction>
            {!hideViewButton && (
                <ViewHistoryButton onClick={onClick}>
                    <span>
                        View History
                    </span>
                    <ViewHistoryIcon src={Icons.RightArrow} />
                </ViewHistoryButton>
            )}
        </WalletStatisticContainer>
    );
};

const WalletStatisticContainer = styled.div`
    background-color: ${Colors.primaryLightest};
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    border-radius: 20px;
    height: 100%;
    display: flex;
    flex-direction: column;
    position: relative;
`;

const WalletTitle = styled.div`
    display: flex;
    flex: 0.5;
    flex-direction: row;
    justify-content: space-between;
    height: 10%;
    padding: 30px 0 20px 0;
    border-bottom: 1px solid ${Colors.primary};
    margin: 0 20px;

    ${media.sm`
        flex-direction: column;
        align-items: center;
    `}
`;

const Title = styled.div<{ negativeTotal: boolean }>`
    display: flex;
    flex-direction: column;
    p {
        font-family: ${Fonts.primary};
        font-size: 14px;
        font-weight: 600;
        color: ${Colors.black};

        ${media.sm`
            font-size: 1.5rem;
            text-align: center;
        `}
    }

    span{
        padding-top: 10px;
        font-family: ${Fonts.primary};
        font-size: 35px;
        font-weight: 600;
        color: ${props => (props.negativeTotal ? Colors.danger : Colors.success)};
        /* margin-right: 10px; */
        @media (max-width: 1580px) {
            font-size: 35px;
        }
    }
`;

const WalletFunctionButtonLeft = styled.button`
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
`;

const WalletFunctionButtonRight = styled.button`
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
`;
const WalletFunction = styled.div<{ loading?: boolean }>`
    display: ${props => (props.loading ? 'none' : 'flex')};
    flex-direction: row;
    gap: 3px;
    align-items: center;

    ${WalletFunctionButtonLeft}, ${WalletFunctionButtonRight}{
        font-weight: 600;
        font-family: ${Fonts.primary};
        color: ${Colors.white};
        background-color: ${Colors.primary};
        border: none;
        width: 110px;
        height: 35px;
        margin-top: 20px;

        transition: scale(1) 0.3s ease;

        &:hover {
            background-color: ${Colors.secondaryLight};
        }

        &:active {
            transform: scale(0.95);
        }

    }
`;

const WalletChart = styled.div`
    display: flex;
    flex-direction: column;
    padding: 20px;
    height: 30%;
    gap: 20px;

    ${media.sm`
        display: none;
    `}
`;

const WalletTransaction = styled.div`
    /* flex: 3; */
    width: 100%;
    padding: 20px;
    height: 50%;

    @media (max-width: 768px) {
        height: 40%;
    }
`;

const WalletTransactionTitle = styled.h3`
    font-family: ${Fonts.primary};
    font-weight: 600;
    font-size: 24px;

    @media (max-width: 768px) {
        font-size: 16px;
    }
`;

const TransactionListContainer = styled.div`
    display: flex;
    flex-direction: column;
    margin: 20px 0;
    gap: 20px;
    overflow-y: hidden;
    height: max-content;

    @media (max-width: 768px) {
        margin-bottom: 40px;
    }
`;

const WalletTransactionItem = styled.div`
    display: grid;
    grid-template-columns: 1fr 2fr 1fr;
    grid-template-rows: 1fr;
    grid-column-gap: 20px;

    p:nth-child(1){
        grid-area: 1 / 1 / 2 / 2;
    }

    p:nth-child(2){
        grid-area: 1 / 2 / 2 / 3;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
    }

    p:nth-child(3){
        grid-area: 1 / 3 / 2 / 4;
        color: ${Colors.success};
        font-weight: 600;
    }

    span{
        grid-area: 1 / 3 / 2 / 4;
        color: ${Colors.danger};
        font-weight: 600;
    }

    @media (max-width: 768px){
        font-size: 12px;
    }
`;

const ViewHistoryIcon = styled(SVG)`
  width: 20px;
  height: 15px;

`;

const ViewHistoryButton = styled.button`
  font-family: ${Fonts.primary};
  font-size: 12px;
  font-weight: 600;
  color: ${Colors.secondaryLight};
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  background-color: ${Colors.primaryLightest};
  bottom: 10px;
  border: 0;
  padding: 10px;
  display: flex;
  justify-content: center;
  gap: 5px;
  &:hover {

    span {
    transform: scale(1.1);
    transition: 0.3s ease-in-out;
  }

    & ${ViewHistoryIcon}{
      transform: translateX(10px);
      transition: 0.3s ease-in-out;
    }
  }
`;

const Filter = styled.div`
    display: flex;
    flex-direction: row;
    width: fit-content;
    height: fit-content;
    align-items: center;
    justify-content: end;
`;

const StyledDatePicker = styled(ReactDatePicker)`
    width: 100px;
    height: 30px;
    margin: 0 20px;
    font-size: 14px;
    text-transform: capitalize;
    text-align: center;
    box-sizing: border-box;
    border: none;
    border-radius: 20px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    cursor: pointer;

    &:focus {
        outline: none;
    }

    .react-datepicker__day {
        cursor: default;
        color: #ccc;
    } 
    .react-datepicker__month-text--disabled, 
    .react-datepicker__quarter-text--disabled, 
    .react-datepicker__year-text--disabled {
        cursor: default;
        color: #ccc;
    }
`;

const RoundInputStyled = styled(RoundInput)`
    display: flex;
    flex-direction: row;
    width: 100%;
`;

const ModalContentMainContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 110%;
    padding: 20px;

    ${media.md`
        height: 100%;
        gap: 10px;
    `}
`;

const ModalContentContainer = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
`;

const mapStateToProps = (state: RootState) => ({
    displayedProperty: Selectors.getPropDisplayedProperty(state),
    allWalletAnalytics: Selectors.getWalletAnalytics(state),
    getWalletAnalyticsAttempt: Selectors.getWalletAnalyticsAttempt(state),
    getWalletAnalyticsFailure: Selectors.getWalletAnalyticsFailure(state),
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
    getWalletAnalytics: (propertyId: string, dateFrom: string, dateTo: string) =>
        dispatch(Actions.getWalletAnalyticsAttempt({ propertyId, dateFrom, dateTo })),
});

export default connect(mapStateToProps, mapDispatchToProps)(WalletStatistic);
